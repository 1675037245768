import React from 'react'
import logo from '../assets/images/logo.svg'
import ScrollAnimation from 'react-animate-on-scroll'

const Header = props => (
  <header id="header" className="alt">
    <ScrollAnimation animateIn="bounceInUp" animateOnce={true} offset={30}>
      <span className="logo">
        <img src={logo} alt="" />
      </span>
      <h1>
        Engineering Leader
        <hr className="header-line header-line-title" />
        UX Web Engineer
      </h1>
      <p>
      Seasoned Engineering Manager specializing in UX/UI design, scalable <br />design systems, and mobile-first strategies.
      </p>
    </ScrollAnimation>
  </header>
)

export default Header
