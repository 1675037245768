import React from 'react'
import ReactSwipe from 'react-swipe'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import bgImage from '../assets/images/bg.jpg'
import 'animate.css/animate.min.css'
import StyledBackgroundSection from '../components/Background'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    let reactSwipeEl
    let reactSwipeElLyftGrubhub
    let reactSwipeElSpotifyRedesign
    let reactSwipeElSpotifyAdStudio
    let reactSwipeElAds
    let reactSwipeElSkins
    let reactSwipeElJobs
    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Mobile First Advocate | New York Based | UX Web Engineer"
        >
          <meta
            property="og:title"
            content="Mobile First Advocate | New York Based | Front-End Engineer"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.mobilefirstdesign.io/" />
          <meta property="og:image" content={bgImage} />
          <meta name="robots" content="nofollow, noindex" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/html5-device-mockups/3.2.1/dist/device-mockups.min.css"
            type="text/css"
          />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <StyledBackgroundSection>
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>
                  As a seasoned Engineering Manager with extensive experience, I
                  excel in steering projects that involve user experience,
                  interface design, and design systems. My leadership approach
                  is grounded in nurturing innovation and encouraging
                  collaborative efforts. I've honed a management style that
                  ensures engineering and design teams work in synergy to attain
                  the highest levels of excellence.
                </p>
                <p>
                  With a deep-rooted understanding of mobile-first design
                  principles, I spearhead initiatives that focus on maximizing
                  user engagement across multiple platforms. My unique skill set
                  effectively bridges the gap between the technical and the
                  aesthetic, enabling me to guide cross-functional teams in
                  producing products that are not only robust from an
                  engineering standpoint but also visually engaging and
                  user-friendly.
                </p>
                <ul className="icons">
                  <li>
                    <a
                      title="link to twitter acount"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/gregolls"
                      className="icon fa-twitter alt"
                    >
                      <span className="label">link to twitter acount</span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="link to gitHub account"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/gfunk1230"
                      className="icon fa-github alt"
                    >
                      <span className="label">link to gitHub account</span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="link to linkedIn profile"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/gregg-campoverde-1916542a/"
                      className="icon fa-linkedin alt"
                    >
                      <span className="label">link to linkedIn profile</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="image">
                <Img fluid={this.props.data.bioImage.childImageSharp.fluid} />
              </div>
            </div>
          </StyledBackgroundSection>
          <section id="first" className="main special">
            <header className="major">
              <h2>Projects</h2>
            </header>
            <div class="device-wrapper">
              <div
                className="device"
                data-device="Macbook2015"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen">
                  <ReactSwipe
                    className="carousel"
                    swipeOptions={{ auto: 3000, speed: 800 }}
                    ref={el => (reactSwipeElLyftGrubhub = el)}
                  >
                    <div div className="laptop-container">
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhub.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div div className="laptop-container">
                      <Img
                        className="laptop-img-scrolled-gh-1"
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhub.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div div className="laptop-container">
                      <Img
                        className="laptop-img-scrolled-gh-2"
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhub.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook1.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook2.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook3.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook4.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook5.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Lyft Grubhub"
                        fluid={
                          this.props.data.lyftgrubhubStoryBook6.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                  </ReactSwipe>
                </div>
              </div>
            </div>
            <div className="hack-week-project">
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElLyftGrubhub.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElLyftGrubhub.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Grubhub</h3>
              <p>
                I led the development of a groundbreaking campaign that hinged
                on a design system I personally engineered, which I dubbed "The
                Cookbook Design System." The architecture for this project
                utilized a potent mix of technologies, including React,
                TypeScript, Storybook, Roll Up, and Styled Components. Each
                component was designed with meticulous attention to detail,
                targeting both accessibility and responsiveness to ensure an
                excellent user experience across devices. This design system
                went beyond simply serving as a collection of reusable UI
                elements; it established a robust framework that adhered to
                rigorous web accessibility standards and ensured cross-device
                compatibility.
              </p>
              <p>
                To validate the system's effectiveness and reliability, I
                undertook extensive testing and validation protocols, confirming
                that all components met or exceeded accessibility guidelines.
                Utilizing Storybook, I provided an interactive UI library that
                made it remarkably straightforward for other developers to
                implement and adapt these components across a variety of
                projects. This initiative served as a testament to both my
                technical expertise and my unwavering commitment to creating
                scalable, accessible, and reusable UI solutions.
              </p>
            </div>

            <div class="device-wrapper">
              <div
                className="device"
                data-device="Macbook2015"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen">
                  <ReactSwipe
                    className="carousel"
                    swipeOptions={{ auto: 3000, speed: 800 }}
                    ref={el => (reactSwipeElSpotifyRedesign = el)}
                  >
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyRedesign.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div className="laptop-container">
                      <Img
                        className="laptop-img-scrolled-1"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyRedesign.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        className="laptop-img-scrolled-2"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyRedesign.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        className="laptop-img-scrolled-3"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyRedesign.childImageSharp.fluid
                        }
                      />
                    </div>
                  </ReactSwipe>
                </div>
              </div>
            </div>

            <div className="hack-week-project">
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElSpotifyRedesign.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElSpotifyRedesign.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Spotify</h3>
              <p>
                Leveraging years of expertise as a seasoned front-end web
                engineer, I played a crucial role in the ambitious redesign of
                Spotify's website. My contributions were grounded in a rich tech
                stack that included HTML, CSS, LESS, Bootstrap, and JavaScript.
                Far from being just another team member, my specialized skills
                in front-end technologies were pivotal in achieving a website
                that not only looked good but also performed seamlessly.
              </p>{' '}
              <p>
                Working in sync with cross-functional teams, I focused on
                crafting a user interface that perfectly blended visual
                aesthetics with high-level functionality. This entailed
                optimizing load times and ensuring robust mobile responsiveness,
                ultimately elevating the user experience to new heights.
              </p>
            </div>

            <div class="device-wrapper">
              <div
                className="device"
                data-device="Macbook2015"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen">
                  <ReactSwipe
                    className="carousel"
                    swipeOptions={{ auto: 3000, speed: 800 }}
                    ref={el => (reactSwipeElSpotifyAdStudio = el)}
                  >
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioHome.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div className="laptop-container">
                      <Img
                        className="laptop-img-scrolled-second-1"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioHome.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        className="laptop-img-scrolled-second-2"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioHome.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        className="laptop-img-scrolled-second-3"
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioHome.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioSignIn.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioDesignSystems1
                            .childImageSharp.fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioDesignSystems2
                            .childImageSharp.fluid
                        }
                      />
                    </div>
                    <div>
                      <Img
                        alt="Spotify"
                        fluid={
                          this.props.data.spotifyAdStudioDesignSystems3
                            .childImageSharp.fluid
                        }
                      />
                    </div>
                  </ReactSwipe>
                </div>
              </div>
            </div>

            <div className="hack-week-project">
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElSpotifyAdStudio.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElSpotifyAdStudio.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Spotify Ad Studio</h3>
              <p>
                As the pioneering front-end web engineer on Spotify's Ad Studio
                project, I was given the crucial task of developing feature
                prototypes for 30-second audio ads aimed at engaging listeners
                across both music and podcasts. Utilizing a specialized tech
                stack of HTML, CSS, Styled Components, and ReactJS, I worked in
                close collaboration with the team responsible for Ad Studio's
                Design Systems, ensuring that my features seamlessly
                complemented existing elements.
              </p>

              <p>
                This role not only showcased my expertise in front-end
                development but also emphasized my commitment to user-centric
                design. My contributions to the Ad Studio Design Systems
                enhanced its robustness and versatility. Through careful
                integration, my work ensured that the audio ads were not merely
                inserted but thoughtfully incorporated into the Spotify app's
                overall user experience, thereby boosting the project's success
                and user engagement metrics.
              </p>
            </div>

            <div className="hack-week-project">
              <div className="icon major ads">
                <Img
                  alt="Spotify Learn to Play Hack Week Project"
                  fluid={
                    this.props.data.spotifyHackProject.childImageSharp.fluid
                  }
                />
              </div>
              <h3 className="portfolio-item-title">Hack Week Project</h3>
              <p>
                Recipient of Spotify's esteemed Hack Week award and voted as the
                "Top Fan Favorite Greatest Hack Project," my innovative
                initiative involved seamlessly integrating tablature into
                Spotify's desktop player.{' '}
              </p>
              <p>
                This groundbreaking feature empowers users to view real-time
                guitar tablatures in sync with the tracks they're listening to.
                Beyond its technical brilliance, the project serves a noble
                cause: it actively promotes music education and offers musicians
                an intuitive way to learn and master their favorite songs.
              </p>
            </div>
            <div className="carousel-wrapper ad-formats-project">
              <ReactSwipe
                className="carousel"
                swipeOptions={{ auto: 3000, speed: 800 }}
                ref={el => (reactSwipeElSkins = el)}
              >
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack One"
                    fluid={this.props.data.spotifySkins1.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack Two"
                    fluid={this.props.data.spotifySkins2.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack Three"
                    fluid={this.props.data.spotifySkins3.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack Four"
                    fluid={this.props.data.spotifySkins4.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack Five"
                    fluid={this.props.data.spotifySkins5.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Theme Hack Six"
                    fluid={this.props.data.spotifySkins6.childImageSharp.fluid}
                  />
                </div>
              </ReactSwipe>
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElSkins.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElSkins.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Hack Week Project</h3>
              <p>
                During the inaugural Spotify Hack Week, I developed a
                customizable theming feature that enables users to personalize
                the appearance of their Spotify desktop player via the settings
                menu.{' '}
              </p>
              <p>
                This user-centric feature allows for a more tailored listening
                experience, letting individuals express their unique style by
                selecting their preferred themes. Under the hood, I leveraged
                JavaScript to dynamically alter CSS tokens, updating them with a
                Photoshop color palette chosen by the user. This project not
                only showcased my proficiency in front-end technologies but also
                emphasized my commitment to enhancing user engagement and
                personalization.
              </p>
            </div>
            <div className="carousel-wrapper ad-formats-project">
              <ReactSwipe
                className="carousel"
                swipeOptions={{ auto: 3000, speed: 800 }}
                ref={el => (reactSwipeElAds = el)}
              >
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds1.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds2.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Learn to Play Hack Week Project"
                    fluid={this.props.data.spotifyAds3.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds4.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds5.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds6.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Ad Formats"
                    fluid={this.props.data.spotifyAds7.childImageSharp.fluid}
                  />
                </div>
              </ReactSwipe>
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElAds.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElAds.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Spotify Ad Formats</h3>
              <p>
                In this high-stakes Spotify project, I drew upon my years of
                front-end engineering experience to revolutionize the ad
                experience for free-tier users. Employing a curated tech stack
                of vanilla JavaScript, HTML, and CSS, I masterfully integrated a
                variety of ad formats into the Spotify player. With a keen eye
                for detail, I engineered these ads to blend seamlessly into the
                existing user interface, thereby minimizing user disruption
                while maximizing ad performance metrics.{' '}
              </p>
              <p>
                My collaboration with stakeholders was pivotal in aligning the
                new ad formats with business objectives, all without sacrificing
                the quality of the user experience. This project serves as a
                testament to my deep technical acumen and my ability to bridge
                business needs with optimal UI/UX design.
              </p>
            </div>
            <div className="carousel-wrapper ad-formats-project">
            <div className="icon major ads">
                <Img
                  alt="Spotify Learn to Play Hack Week Project"
                  fluid={
                    this.props.data.spotifyBrazil1.childImageSharp.fluid
                  }
                />
              </div>
              <h3 className="portfolio-item-title">Spotify Brazil Launch</h3>
              <p>
                As part of an agile team focused on the high-stakes launch of
                Spotify in Brazil, I served as the lead engineer responsible for
                the UX/UI design of the pivotal landing page and associated
                marketing materials. The landing page was designed not just as a
                gateway for new users, but also as an engagement tool that it
                featured an early-access sign-up form and an innovative feature
                allowing users to obtain additional passes to share Spotify with
                their friends.{' '}
              </p>

              <p>
                My role required close collaboration with marketing, product,
                and other engineering teams to ensure a cohesive and impactful
                user journey. I used advanced front-end technologies and best
                practices to build a responsive, visually appealing, and
                user-friendly interface. This project showcased my versatility
                in engineering and design, my ability to work in a fast-paced,
                cross-functional environment, and my commitment to delivering an
                exceptional user experience.
              </p>
            </div>
            <div className="carousel-wrapper ad-formats-project">
              <ReactSwipe
                className="carousel"
                swipeOptions={{ auto: 3000, speed: 800 }}
                ref={el => (reactSwipeElJobs = el)}
              >
                <div className="icon major ads">
                  <Img
                    alt="Spotify Jobs"
                    fluid={this.props.data.spotifyJobs1.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Jobs"
                    fluid={this.props.data.spotifyJobs5.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Jobs"
                    fluid={this.props.data.spotifyJobs2.childImageSharp.fluid}
                  />
                </div>
                <div className="icon major ads">
                  <Img
                    alt="Spotify Jobs"
                    fluid={this.props.data.spotifyJobs3.childImageSharp.fluid}
                  />
                </div>
              </ReactSwipe>
              <p className="ads-controls">
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeElJobs.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeElJobs.next()}
                />
              </p>
              <h3 className="portfolio-item-title">Spotify Jobs Board</h3>
              <p>
                In a cross-functional project that brought together human
                resources, finance, product, and engineering teams, I
                spearheaded the development of an internal referral program for
                job postings <br />
                within the company. As the point person for the job listings
                section, I was solely responsible for the UX/UI engineering
                aspects.
              </p>
              <p>
                My role involved not only the front-end development but also
                close collaboration with the backend team to ensure seamless
                data integration. Working alongside product and finance
                departments, I made certain that the platform met key
                organizational objectives while also optimizing for user
                engagement and accessibility.
              </p>{' '}
              <p>
                Utilizing modern front-end technologies and methodologies, I
                created an intuitive and user-friendly interface that
                streamlined the employee referral process, ultimately aiding in
                faster and more effective talent acquisition. This project not
                only showcased my technical skills but also demonstrated my
                ability to lead and collaborate effectively within a
                multi-disciplinary environment.
              </p>
            </div>
          </section>

          <section id="second" className="main special testimonials">
            <header className="major">
              <h2>Testimonials</h2>
            </header>
            <div className="carousel-wrapper">
              <ReactSwipe
                className="carousel"
                swipeOptions={{ auto: 3000, speed: 800 }}
                ref={el => (reactSwipeEl = el)}
              >
                <p className="content">
                  "As I remember, Greg Campoverde was a very productive person.
                  A very careful deadline oriented and eager colleague. Always
                  punctual developer, understands complex matters even when
                  outside of his direct area of expertise. His technical skills
                  and intelligence makes Greg Campoverde to view solutions
                  instead of problems. It's amazing to work with such a person."
                  <br />
                  <strong>
                    - Dominic Hanard, QA Engineer skilled in Agile methodologies
                  </strong>
                </p>
                <p className="content">
                  "Greg and I worked together back when I was pulling together a
                  swat team to launch Spotify in Brazil. While this was an often
                  hectic assignment, spanning many areas of the product and
                  business, Greg was able to keep it cool and consistently
                  deliver. He is the kind of professional I like to have in a
                  team." <br />
                  <strong>
                    - Ricardo Vice Santos, kencko.com (ex Spotify, Roger,
                    Reaction.cam)
                  </strong>
                </p>
                <p className="content">
                  "Greg is a talented web designer and great to work with. Not
                  only is he an amazing designer, but he has ingenious web
                  skills with an eye for detail. He is a passionate designer and
                  always seems to have great awareness of new creative
                  strategies and technology, which makes him a great asset to
                  any company." <br />
                  <strong>
                    - William Rodriguez, Senior Web Designer at Bronson
                    Laboratories
                  </strong>
                </p>
                <p className="content">
                  "I wanted to take a moment and express that Greg has gone far
                  above and beyond my expectations for my website design. He
                  responds to my questions during the weekend and has had a
                  patience with me that I am not sure I would have extend to
                  myself! Greg is professional, prompt, and most importantly, he
                  listens to what I want and then implements it." <br />
                  <strong>
                    - Mark A. Gould, The Law Office Of Mark A. Gould, P.C.
                  </strong>
                </p>
                <p className="content">
                  "I wanted to send you a quick email and thank you for the
                  amazing job that you did building a couple of my Prosites. The
                  clients that I am speaking of in particular are AA Movers LLC,
                  Tri State Appliance , and New Life Home Renovations LLC. AA
                  Movers LLC and New Life Home Renovations have gone live and
                  Tri State Appliance will go live by Feb 1st. My client Baltaz
                  is extremely pleased with the job that you did." <br />
                  <strong>
                    - Tyler Thovson, Internet Marketing Consultant
                  </strong>
                </p>
                <p className="content">
                  "Greg was a great team member to work with through the design
                  process. He and I always communicated well and had talks
                  before speaking with the client. We would brainstorm and try
                  to come up with an agenda of ideas before we streamlined the
                  process and stay on target. Greg really helped to facilitate a
                  smooth process and I look forward working with him on future
                  projects." <br />
                  <strong>- Brian Thomason, Search Marketing SEM & SEO</strong>
                </p>
                <p className="content">
                  "In regarding our satisfaction with your work product our firm
                  has been extremely satisfied with your work. From the time we
                  began our consultation process with you, you were extremely
                  professional and creative. You seemed to take the initial
                  questioners we completed and transform them into a site that
                  reflects the culture and feel of our firm." <br />
                  <strong>
                    - Laura Whitley,Paralegal At Law Office Of Gerald C. Moton
                  </strong>
                </p>
                <p className="content">
                  "I have been a Yodle customer for about four months. So far,
                  my experience has been outstanding. A big part of that
                  experience was the conception, construction, and polishing of
                  my website done by Greg. Honestly, I was amazed he didn't
                  start gently mentioning that we had gone well beyond the
                  normal number of edits." <br />
                  <strong>- Jeffrey N. Jackson, Jackson Murphy, PLLC</strong>
                </p>
              </ReactSwipe>
              <p>
                <i
                  className="icon fa-chevron-circle-left"
                  onClick={() => reactSwipeEl.prev()}
                />
                <i
                  className="icon fa-chevron-circle-right"
                  onClick={() => reactSwipeEl.next()}
                />
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const ImageQuery = graphql`
  query {
    bioImage: file(relativePath: { eq: "pic01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 318) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageOne: file(relativePath: { eq: "adstudio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "brands.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageThree: file(relativePath: { eq: "royston.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageFour: file(relativePath: { eq: "homepage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageFive: file(relativePath: { eq: "sonos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageSix: file(relativePath: { eq: "weddings.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageSeven: file(relativePath: { eq: "martha-stewart.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageEight: file(relativePath: { eq: "eyewitness.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    scm: file(relativePath: { eq: "scm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gina: file(relativePath: { eq: "gina.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    americanMade: file(relativePath: { eq: "american-made.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyHackProject: file(relativePath: { eq: "spotify-tabs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins1: file(relativePath: { eq: "skins-demo-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins2: file(relativePath: { eq: "skins-demo-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins3: file(relativePath: { eq: "skins-demo-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins4: file(relativePath: { eq: "skins-demo-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins5: file(relativePath: { eq: "skins-demo-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifySkins6: file(relativePath: { eq: "skins-demo-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds1: file(relativePath: { eq: "ads-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds2: file(relativePath: { eq: "ads-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds3: file(relativePath: { eq: "ads-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds4: file(relativePath: { eq: "ads-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds5: file(relativePath: { eq: "ads-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds6: file(relativePath: { eq: "ads-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAds7: file(relativePath: { eq: "ads-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyBrazil1: file(relativePath: { eq: "brazil-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyBrazil2: file(relativePath: { eq: "brazil-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyJobs1: file(relativePath: { eq: "spotify-jobs-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyJobs2: file(relativePath: { eq: "spotify-jobs-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyJobs3: file(relativePath: { eq: "spotify-jobs-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyJobs4: file(relativePath: { eq: "spotify-jobs-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyJobs5: file(relativePath: { eq: "spotify-jobs-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhub: file(relativePath: { eq: "lyftgrubhub.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook1: file(relativePath: { eq: "storybook1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook2: file(relativePath: { eq: "storybook2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook3: file(relativePath: { eq: "storybook3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook4: file(relativePath: { eq: "storybook4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook5: file(relativePath: { eq: "storybook5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lyftgrubhubStoryBook6: file(relativePath: { eq: "storybook6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyRedesign: file(relativePath: { eq: "spotify.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAdStudioHome: file(
      relativePath: { eq: "spotify-ad-studio-home-resize.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAdStudioSignIn: file(
      relativePath: { eq: "spotify-ad-studio-sign-in-resize.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAdStudioDesignSystems1: file(
      relativePath: { eq: "ad-studio-design-system-1-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAdStudioDesignSystems2: file(
      relativePath: { eq: "ad-studio-design-system-2-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spotifyAdStudioDesignSystems3: file(
      relativePath: { eq: "ad-studio-design-system-3-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Index
